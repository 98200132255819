import React from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component } from 'react';
import Helmet from 'react-helmet';

export default class About extends Component {
    
    constructor(){
        super();
        this.state={
          notifications:[],
          reviews:[],
          courses:[]
        }
      }
     
      async componentDidMount() {
        this.getList();
      }
      //API CALLS
      getList=()=>{
        this.getUser();
      }

      notificationDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

      async getUser(){
        let usersData=await axios.get('/about-page-data')
                      .then(res=>{
                          this.setState({
                            notifications:res.data.notifications,
                            reviews:res.data.collegerates,
                            courses:res.data.courses
                          },()=>{
                            // console.log(this.state)
                          })
                      })
                      .catch(err=>{
                          console.log(err);
                      })
      }

    render(){
        const {notifications}=this.state;
        const {reviews}=this.state;
        const {courses}=this.state;
        return (
          <div id="content-wrapper" className="site-content-wrapper">
            <Helmet>
                <title>About Nursing Colleges and Courses in Bangalore – Get Direct Admissions for Nursing Colleges</title>
                <meta name="description" content="About BCA Colleges in Bangalore services - We are one of the Top leading educational partners having services all over the World for academic & career guidance."/>
                <meta name="keywords" content="leading educational advisory centres in Bangalore,management seats, direct admission services, career guidance, educational counsellors in Bangalore."/>
            </Helmet>
            <div id="content" className="site-content container">
              <div id="content-inner" className="site-content-inner row multi-columns-row">
                  <div id="primary" className="content-area col-md-8 col-lg-8 col-xs-12 sub">
                    <main id="main" className="site-main">
                        <div className="row">
                          <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
                              <div className="vc_column-inner tm-responsive-custom-12751771">
                                <div className="wpb_wrapper">
                                    <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                      <section className="tm-vc_cta3-container tm-sepcolor-default">
                                          <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                            <div className="tm-vc_cta3_content-container">
                                                <div className="tm-vc_cta3-content">
                                                  <header className="tm-vc_cta3-content-header tm-wrap">
                                                      <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                        <h1 className="tm-custom-heading ">About <span>Nursing Colleges</span> in Bangalore</h1>
                                                      </div>
                                                      <div className="heading-seperator"><span></span></div>
                                                  </header>
                                                  <div className="tm-cta3-content-wrapper">
                                                      <p>
                                                        Nursing Colleges in Bangalore was established in 2020 with the main objective of assisting healthcare aspirants to choose their ideal Nursing college and ensure personal and professional satisfaction. We have helped and guided many candidates in choosing and finalizing the <a href="https://www.nursingcollegesinbangalore.com/colleges">best nursing college in Bangalore</a> (<b>Nursing Colleges in Bangalore</b>) to build their healthcare career and to strive towards a prosperous and successful life. We have assisted and helped candidates by directly connecting them to the <a href="https://www.nursingcollegesinbangalore.com/">Top Nursing colleges in Bangalore</a> (<b>Top Nursing Colleges in Bangalore</b>) where they have been provided the freedom to choose the Nursing course, specialization and college of their choice with confidence and trust.
                                                      </p>
                                                      <p><b>The exclusive services offered by Nursing Colleges in Bangalore are:</b></p>
                                                  </div>
                                                </div>
                                            </div>
                                          </div>
                                      </section>
                                    </div>
                                    <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                      <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
                                          <div className="vc_column-inner  ">
                                            <div className="wpb_wrapper">
                                                <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                  <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Updated information about Nursing colleges in Bangalore</span></li>
                                                  <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Genuine ranking and rating of Nursing colleges</span></li>
                                                  <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Latest College notifications and updates</span></li>
                                                  <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Admission process of different top Nursing colleges</span></li>
                                                  <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Notifications about Government and Private Scholarship Exams for Nursing courses</span></li>
                                                  <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Genuine reviews about the college from alumni students and present students</span></li>
                                                  <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Comparison of different Nursing colleges</span></li>
                                                  <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Providing the verified List of reputed Nursing colleges from our Research Team</span></li>
                                                  <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Providing information about identification of fake colleges and universities</span></li>
                                                  <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Suggestion of Nursing courses and colleges on the basis of ROI</span></li>
                                                  <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Recommending Nursing courses and colleges based on professional scope</span></li>
                                                </ul>
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                    <br/>
                                    <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                      <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
                                          <div className="vc_column-inner  ">
                                            <div className="wpb_wrapper">
                                                <p>Nursing Colleges in Bangalore is the Number One educational portal for Nursing education in Bangalore. It has been recognized and approved by most of the top healthcare colleges in Bangalore. Lakhs of students have benefited from our exclusive education services and prefer our website for the latest updates and college notifications. Most of the leading administrative departments in India recommend our services for the candidate’s course-related requirements and for the latest information and notifications.</p>
                                                <p>If you are a healthcare aspirant and want to study in the best Nursing college in Bangalore BUT you are confused about which college to choose, your college search ends HERE!</p>
                                                <p>We provide actual information and comparison about the most popular Nursing courses and colleges in Bangalore that help to make the finalizing of your dream course and college a smooth, easy and hassle-free process.</p>
                                                <p>There are more than 100 top Nursing colleges in Bangalore City – among which there are good colleges, reputed colleges, recognized colleges, non-recognized and unapproved colleges. Some of the colleges don’t have proper affiliations and approvals. There are many of such Nursing colleges spread across Bangalore that carry out fake advertising and publicity on a large scale to grab the attention of candidates. These colleges conduct fake marketing through false tactics that include promising very good placements in top hospitals and healthcare companies, promising admission through online processes where candidates are not encouraged to directly visit the college, by displaying only the visual highlights of the college and by displaying fake Recruitment Statistics to attract candidates. These colleges DO NOT fulfill these promises once the admission is processed. They manipulate their ratings and reviews to draw curious candidates, only to cheat them later. These colleges do high level advertising and marketing to attract naive candidates. We recommend candidates not to blindly trust such marketing techniques without verifying with various dependable and reliable sources. Rest assured, Nursing Colleges in Bangalore provides genuine and authentic information with actual reviews and ratings about the top Nursing colleges in Bangalore.</p>
                                                <p>If you need any help or more information about the latest details related to Nursing colleges in Bangalore, you can contact us.</p>
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                        </div>
                    </main>
                  </div>
                  <aside id="sidebar-right" className="widget-area col-md-4 col-lg-4 col-xs-12 sidebar">
                    <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
                        <h3 className="widget-title">Notifications</h3>
                        <ul className="p-10 tm-recent-post-list scrollable-notification">
                        {notifications && notifications.length > 0 && notifications.map((notification, index) => {
                          return (
                            <li className="tm-recent-post-list-li" key={`n${notification.id}`}>
                                <Link to={`/notifications/${notification.slug}`}>
                                <img width="150" height="150" src={`/storage/notifications/${notification.image2}`} className="attachment-thumbnail size-thumbnail wp-post-image lozad" alt={notification.title} src={`/storage/notifications/${notification.image2}`} data-loaded="true"/></Link>
                                <Link to={`/notifications/${notification.slug}`}>{notification.title}</Link>
                                <span className="post-date">{this.notificationDate(new Date(notification.updated_at))}</span>
                            </li>
                          );
                        })}
                        </ul>
                    </aside>
                    <aside id="categories-5" className="rating widget-even widget-10 widget widget_categories">
                        <h3 className="widget-title">Reviews & Ratings of Nursing Colleges in Bangalore</h3>
                        <div className="rate-box">
                          <div className="recent-box text-left">
                          {reviews && reviews.length > 0 && reviews.map((reviews, index) => {
                            const oneStar = (reviews.rating >= 1) ?  'fa fa-star' :  'fa fa-star-o' ;
                            const twoStar = (reviews.rating >= 2) ?  'fa fa-star' :  'fa fa-star-o' ;
                            const threeStar = (reviews.rating >= 3) ?  'fa fa-star' :  'fa fa-star-o' ;
                            const fourStar = (reviews.rating >= 4) ?  'fa fa-star' :  'fa fa-star-o' ;
                            const fiveSar = (reviews.rating >= 5) ?  'fa fa-star' :  'fa fa-star-o' ;
                            return (
                              <div className="recent" key={`r${reviews.id}`}>
                                  <h6>
                                    <span>
                                        <div className="rating-stars">
                                          <ul id="">
                                              <li className="star rate-this about-rating-stars" title="1 star" data-value="1">
                                                <i className={oneStar}></i>
                                              </li>
                                              <li className="star rate-this about-rating-stars" title="2 stars" data-value="2">
                                                <i className={twoStar}></i>
                                              </li>
                                              <li className="star rate-this about-rating-stars" title="3 stars" data-value="3">
                                                <i className={threeStar}></i>
                                              </li>
                                              <li className="star rate-this about-rating-stars" title="4 stars" data-value="4">
                                                <i className={fourStar}></i>
                                              </li>
                                              <li className="star rate-this about-rating-stars" title="5 stars" data-value="5">
                                                <i className={fiveSar}></i>
                                              </li>
                                          </ul>
                                        </div>
                                    </span>
                                    <span>|</span> {reviews.name} <span>|</span> <Link to={`/colleges/${reviews.url}`}>{reviews.college}</Link> <span>|</span> <span>{reviews.date}</span>
                                  </h6>
                                  <p>{reviews.review}</p>
                              </div>
                            );
                          })}
                          </div>
                        </div>
                    </aside>
                    <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
                        <h3 className="widget-title" style={{backgroundColor: '#134054'}}>Similar trending courses</h3>
                        <ul className="p-10 similar-crs tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                          {courses && courses.length > 0 && courses.map((course, index) => {
                          return (
                            <li key={`cs${course.id}`}>
                                <i className="tm-skincolor fa fa-arrow-circle-right"></i>
                                <span className="tm-list-li-content">
                                  <Link to={`/courses/${course.slug}`}>{course.course}</Link>
                                </span>
                            </li>
                          );
                          })}
                        </ul>
                    </aside>
                    <aside id="acadevo_contact_widget-4" className="widget-even widget-14 widget acadevo_contact_widget">
                        <a className="hover" href="https://www.bptcollegesinbangalore.com/" target="_blank">
                          <h3 className="widget-title">Click to know about BPT Colleges in Bangalore <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></h3>
                        </a>
                    </aside>
                  </aside>
              </div>
            </div>
        </div>
        );
    }
}

// export default Home;