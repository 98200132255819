import React from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component } from 'react';
import Helmet from 'react-helmet';
import { ThreeDots } from 'react-loader-spinner'

export default class Colleges extends Component {
    
    constructor(){
        super();
        this.state={
          colleges:[],
          isLoading:false
        }
      }
     
      async componentDidMount() {
        this.getList();
      }
      //API CALLS
      getList=()=>{
        this.getUser();
      }

      async getUser(){
        this.setState({isLoading:true})
        let usersData=await axios.get('/colleges-page-data')
                    .then(res=>{
                        this.setState({
                            colleges:res.data.colleges
                        },()=>{
                        })
                        this.setState({isLoading:false})
                    })
                    .catch(err=>{
                        this.setState({isLoading:false})
                        console.log(err);
                    })
      }

    //   handleSubmit(event) {
    //     event.preventDefault();
    //     // const email = event.target.elements.email.value;
    //     // const name = event.target.elements.name.value;
    //     const formData = new FormData(event.target);
    //     axios.post('/api/store-enquiry', formData)
    //     .then(({data})=>{
    //         event.target.reset()
    //         if(data.status === 200){
    //             Swal.fire({
    //                 icon:"success",
    //                 text:data.message
    //             })
    //         } else {
    //             Swal.fire({
    //                 text:'Error please try again later!',
    //                 icon:"error"
    //             })
    //         }
    //     })
    //   }

    render(){
        const {colleges, isLoading}=this.state;
        return (
            !isLoading ? 
	        colleges!==null?
            <div id="content-wrapper" className="site-content-wrapper">
                <Helmet>
                    <title>Find the List of Top BCA Colleges in Bangalore | Get Direct Admissions for BCA Colleges 2023-24</title>
                    <meta name="description" content="Top Notch BCA Colleges in Bangalore byspecialisation,Fees,Ranking,Admission and Placement. Check all the BCA colleges, Compare, Select and Book your seats."/>
                    <meta name="keywords" content="List of bca colleges in Bangalore, top 5 bca colleges in Bangalore, top 10bca colleges in Bangalore, bca admissions in Bangalore,good bca colleges in Bangalore."/>
                </Helmet>
                <div id="content" className="site-content container">
                        <div id="content-inner" className="site-content-inner row multi-columns-row">
                        <div id="primary" className="content-area col-md-12 col-lg-12 col-xs-12 sub">
                            <main id="main" className="site-main">
                                    <div className="row">
                                        <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
                                            <div className="vc_column-inner tm-responsive-custom-12751771">
                                                <div className="wpb_wrapper">
                                                    <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                                        <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                            <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                                <div className="tm-vc_cta3_content-container">
                                                                    <div className="tm-vc_cta3-content">
                                                                        <header className="tm-vc_cta3-content-header tm-wrap">
                                                                            <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                                                {/* <h3 className="tm-custom-heading">List of Top BCA Colleges in Bangalore</h3>  */}
                                                                                <h1 class="tm-custom-heading ">List of <span>Top Nursing Colleges</span> in Bangalore</h1>
                                                                            </div>
                                                                            <div className="heading-seperator"><span></span></div>
                                                                        </header>
                                                                        <div className="tm-cta3-content-wrapper">
                                                                                <div className="themetechmount-boxes themetechmount-boxes-course themetechmount-boxes-view-default themetechmount-boxes-col-four themetechmount-boxes-sortable- themetechmount-boxes-textalign-left themetechmount-boxes-sortablebutton-">
                                                                                <div className="themetechmount-boxes-inner themetechmount-boxes-course-inner colleges-box">
                                                                                    <div className="row multi-columns-row themetechmount-boxes-row-wrapper">
                                                                                        {colleges && colleges.length > 0 && colleges.map((college, index) => {
                                                                                            const oneStar = (college.rating >= 1) ?  'fa fa-star' :  'fa fa-star-o' ;
                                                                                            const twoStar = (college.rating >= 2) ?  'fa fa-star' :  'fa fa-star-o' ;
                                                                                            const threeStar = (college.rating >= 3) ?  'fa fa-star' :  'fa fa-star-o' ;
                                                                                            const fourStar = (college.rating >= 4) ?  'fa fa-star' :  'fa fa-star-o' ;
                                                                                            const fiveSar = (college.rating >= 5) ?  'fa fa-star' :  'fa fa-star-o' ;
                                                                                            return (
                                                                                                <div className="tm-box-col-wrapper col-lg-4 col-sm-6 col-md-4 col-xs-12" key={`k${college.id}`}>
                                                                                                    <article className="themetechmount-boxes themetechmount-box-course themetechmount-boxe-view-top-image themetechmount-course-box-view-top-image">
                                                                                                        <div className="themetechmount-post-item">
                                                                                                            <div className="themetechmount-box-content">
                                                                                                                <div className="themetechmount-post-item-inner">
                                                                                                                    <div className="tm-featured-wrapper tm-lp_course-featured-wrapper">
                                                                                                                        <Link to={`/colleges/${college.slug}`}>
                                                                                                                            <img width="526" height="470" src={`/storage/colleges/${college.image}`} className="attachment-themetechmount-img-coursebox size-themetechmount-img-coursebox wp-post-image" alt=""/>
                                                                                                                        </Link>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="themetechmount-box-bottom-content">
                                                                                                                <div className="tm-bottom-content-inner">
                                                                                                                    <div className="themetechmount-box-title">
                                                                                                                        <h6>
                                                                                                                            <Link to={`/colleges/${college.slug}`}>{college.college}</Link>
                                                                                                                        </h6>
                                                                                                                    </div>
                                                                                                                    <div className="themetechmount-box-desc-text">
                                                                                                                        Bangalore		
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="themetechmount-course-box-meta">
                                                                                                                    <div className="rating-stars">
                                                                                                                        <ul id="">
                                                                                                                            <li className="star rate-this about-rating-stars" title="5 stars" data-value="5">
                                                                                                                            <i className={oneStar}></i>
                                                                                                                            <i className={twoStar}></i>
                                                                                                                            <i className={threeStar}></i>
                                                                                                                            <i className={fourStar}></i>
                                                                                                                            <i className={fiveSar}></i>
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                    </div>
                                                                                                                    <span>(<b>{college.count}/5</b> based on {college.total}  reviews) </span>
                                                                                                                </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </article>
                                                                                                </div>
                                                                                            );
                                                                                        })}
                                                                                        
                                                                                
                                                                                    </div>
                                                                                    <div className="clearfix"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </main>
                        </div>
                
                    </div>
                </div>
            </div>
        :
        <h1> Can't connect to server ! </h1>
        :
        <div className="row">
            <div className="col-md-12 text-center">
                <ThreeDots
                visible={true}
                height="50"
                width="50"
                color="#black"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{textAlign:'center'}}
                wrapperClass="loader"
                />
            </div>
      </div>
        );
    }
}

// export default Home;