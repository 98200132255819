import React from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component } from 'react';
import Helmet from 'react-helmet';
import Select from 'react-select'
import countryFile from './Country';
import checkNumber from './CheckMobileNumber';

export default class Syllabus extends Component {
    
    constructor(props){
        super(props);
        this.state={
          blogs:[],
          notifications:[],
          reviews:[],
		  countries: [], 
		  states: [],
		  stateValue:'',
		  mobile:''
        }
      }

	  selectCountry = (val) => {
		this.setState({ stateValue: '' });
		const state_arr = countryFile.s_a[val.key].split("|"); 
		const stateArray = [];
		stateArray.push({
			value: '', 
			label: 'Select'
		});
		for (var i = 0; i < state_arr.length; i++) {
			stateArray.push({
			value: state_arr[i], 
			label: state_arr[i]
			});
		}
		this.setState({ states: stateArray });
		var Country = val.value;
        let check = checkNumber.checkNumber(Country,this.state.mobile);
        if(check === 2){
          this.setState({mobile:''});
        }
	  }
	
	  selectRegion = (val) => {
		if(val.value !== '' && val.value !== null){
			this.setState({stateValue:val});
		} else {
			this.setState({stateValue:''});
		}
	  }

	  updateMobile = (value) => {
		this.setState({mobile:value});
	  }
     
      componentDidMount() {
		const countryArray = [];
        var country1 = countryFile.country_arr;
        countryArray.push({
          value: '', 
          label: 'Select',
          key:[0]
        });
        for (var i = 0; i < country1.length; i++) {
          countryArray.push({
            value: country1[i], 
            label: country1[i],
            key:[i+1]
          });
        }
        this.setState({countries:countryArray});
		this.getUser();
      }
      //API CALLS
      getList=()=>{
        this.getUser();
      }

      async getUser(){
        let usersData=await axios.get('/syllabus-page-data')
                        .then(res=>{
                        //   return res.data;
                          this.setState({
                            blogs:res.data.blogs,
                            notifications:res.data.notifications,
                            reviews:res.data.collegerates
                          },()=>{
                            // console.log(this.state)
                          })
                        })
                        .catch(err=>{
                          console.log(err);
                        })
      }

      handleSubmit(event) {
        // console.log(event.target.country.value);
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        console.log(formData);
        axios.post('/store-enquiry', formData)
        .then(({data})=>{
            event.target.reset()
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'We have already received an enquiry from this mobile number or email.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }

	  notificationDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

	  blogDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

    render(){
        const {blogs}=this.state;
        const {notifications}=this.state;
        const {reviews}=this.state;
		const { countries, states, stateValue, mobile } = this.state;
        return (
          <div id="content-wrapper" className="site-content-wrapper">
            <Helmet>
                <title>BCA Syllabus</title>
            </Helmet>
              <div id="content" className="site-content container">
                    <div id="content-inner" className="site-content-inner row multi-columns-row">
					  <div id="primary" className="content-area col-md-8 col-lg-8 col-xs-12 sub">
						 <main id="main" className="site-main">
								<div className="row">
									<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
										<div className="vc_column-inner tm-responsive-custom-12751771">
											<div className="wpb_wrapper">
												<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
													<section className="tm-vc_cta3-container tm-sepcolor-default">
														<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
															<div className="tm-vc_cta3_content-container">
																<div className="tm-vc_cta3-content">
																	<header className="tm-vc_cta3-content-header tm-wrap">
																		<div className="tm-vc_cta3-headers tm-wrap-cell">
																			<h3 className="tm-custom-heading ">BCA Syllabus</h3> </div>
																		<div className="heading-seperator"><span></span></div>
																	</header>
																	<div className="tm-cta3-content-wrapper">
																		<p>Below is an overview of the topics which the student will study in a BCA programme across 6 semesters:</p>
																	</div>
																</div>
															</div>
														</div>
													</section>
												</div>
												<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
													<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
														<div className="vc_column-inner  ">
																			<div className="wpb_wrapper">
																				<table className="table table-hover syllabus-table">
																					<thead>
																					<tr>
																					<th width="50%">Semester 1</th>
																					<th width="50%">Semester 2</th>
																					</tr>
																					</thead>
																					<tbody>
																					<tr>
																					<td>Hardware Lab (CIA Only)</td>
																					<td>Case Tools Lab (CIA Only)</td>
																					</tr>
																					<tr>
																					<td>Creative English</td>
																					<td>Communicative English</td>
																					</tr>
																					<tr>
																					<td>Foundational Mathematics</td>
																					<td>Basic Discrete Mathematics</td>
																					</tr>
																					<tr>
																					<td>Statistics I For BCA</td>
																					<td>Operating Systems</td>
																					</tr>
																					<tr>
																					<td>Digital Computer Fundamentals</td>
																					<td>Data Structures</td>
																					</tr>
																					<tr>
																					<td>Introduction To Programming Using C</td>
																					<td>Data Structures Lab</td>
																					</tr>
																					<tr>
																					<td>C Programming Lab</td>
																					<td>Visual Programming Lab</td>
																					</tr>
																					<tr>
																					<td>PC Software Lab</td>
																					<td>&nbsp;</td>
																					</tr>
																					</tbody>
																				</table>
																				<table className="table table-hover syllabus-table">
																					<thead>
																					<tr>
																					<th width="50%">Semester 3</th>
																					<th width="50%">Semester 4</th>
																					</tr>
																					</thead>
																					<tbody>
																					<tr>
																					<td>Interpersonal Communication</td>
																					<td>Professional English</td>
																					</tr>
																					<tr>
																					<td>Introductory Algebra</td>
																					<td>Financial Management</td>
																					</tr>
																					<tr>
																					<td>Financial Accounting</td>
																					<td>Computer Networks</td>
																					</tr>
																					<tr>
																					<td>Software Engineering</td>
																					<td>Programming In Java</td>
																					</tr>
																					<tr>
																					<td>Database Management Systems</td>
																					<td>Java Programming Lab</td>
																					</tr>
																					<tr>
																					<td>Object Oriented Programming Using C++</td>
																					<td>DBMS Project Lab</td>
																					</tr>
																					<tr>
																					<td>C++ Lab</td>
																					<td>Web Technology Lab</td>
																					</tr>
																					<tr>
																					<td>Oracle Lab</td>
																					<td>Language Lab (CIA Only)</td>
																					</tr>
																					<tr>
																					<td>Domain Lab (CIA Only)</td>
																					<td>&nbsp;</td>
																					</tr>
																					</tbody>
																				</table>
																				<table className="table table-hover syllabus-table">
																					<thead>
																					<tr>
																					<th width="50%">Semester 5</th>
																					<th width="50%">Semester 6</th>
																					</tr>
																					</thead>
																					<tbody>
																					<tr>
																					<td>Unix Programming</td>
																					<td>Design And Analysis Of Algorithms</td>
																					</tr>
																					<tr>
																					<td>OOAD Using UML</td>
																					<td>Client-Server Computing</td>
																					</tr>
																					<tr>
																					<td>User Interface Design</td>
																					<td>Computer Architecture</td>
																					</tr>
																					<tr>
																					<td>Graphics And Animation</td>
																					<td>Cloud Computing</td>
																					</tr>
																					<tr>
																					<td>Python Programming</td>
																					<td>Multimedia Applications</td>
																					</tr>
																					<tr>
																					<td>Business Intelligence</td>
																					<td>Introduction To Soft Computing</td>
																					</tr>
																					<tr>
																					<td>Unix Lab</td>
																					<td>Advanced Database Management System</td>
																					</tr>
																					<tr>
																					<td>Web Designing Project</td>
																					<td>&nbsp;</td>
																					</tr>
																					<tr>
																					<td>Graphics And Animation Lab</td>
																					<td>&nbsp;</td>
																					</tr>
																					<tr>
																					<td>Python Programming Lab</td>
																					<td>&nbsp;</td>
																					</tr>
																					<tr>
																					<td>Business Intelligence Lab</td>
																					<td>&nbsp;</td>
																					</tr>
																					</tbody>
																				</table>
																			</div>
														</div>
													</div>
												</div>
												
												
											</div>
										</div>
									</div>
								</div>

						 </main>
					  </div>
					  <aside id="sidebar-right" className="widget-area col-md-4 col-lg-4 col-xs-12 sidebar">
						 <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							<h3 className="widget-title">Notifications</h3>
							<ul className="p-10 tm-recent-post-list">
								{notifications && notifications.length > 0 && notifications.map((notification, index) => {
                        			return (
										<li className="tm-recent-post-list-li" key={`n${notification.id}`}>
											<Link to={`/notifications/${notification.slug}`}>
												<img width="150" height="150" src={`/storage/notifications/${notification.image2}`} alt=""/>
											</Link>
											<Link to={`/notifications/${notification.slug}`}>{notification.title}</Link>
											<span className="post-date">{this.blogDate(new Date(notification.updated_at))}</span>
										</li>
								    );
							    })}
							</ul>
						 </aside>
						 <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							<h3 className="widget-title" style={{backgroundColor: '#26272a'}}>Blogs</h3>
							<ul className="p-10 tm-recent-post-list">
								{blogs && blogs.length > 0 && blogs.map((blog, index) => {
									return (
									<li className="tm-recent-post-list-li" key={`bl${blog.id}`}>
										<Link to={`/blogs/${blog.slug}`}><img width="150" height="150" src={`/storage/blogs/${blog.image2}`} className="attachment-thumbnail size-thumbnail wp-post-image" alt={blog.title}/></Link>
										<Link to={`/blogs/${blog.slug}`}>{blog.title}</Link>
										<span className="post-date">{this.notificationDate(new Date(blog.updated_at))}</span>
									</li>
									)
								})}
							</ul>
						 </aside>
						 <aside id="categories-5" className="rating widget-even widget-10 widget widget_categories">
							<h3 className="widget-title" style={{backgroundColor: '#26272a'}}>Reviews & Ratings of BCA Colleges in Bangalore</h3>
							<div className="rate-box">
							{reviews && reviews.length > 0 && reviews.map((review, index) => {
								const oneStar = (review.rating >= 1) ?  'fa fa-star' :  'fa fa-star-o' ;
								const twoStar = (review.rating >= 2) ?  'fa fa-star' :  'fa fa-star-o' ;
								const threeStar = (review.rating >= 3) ?  'fa fa-star' :  'fa fa-star-o' ;
								const fourStar = (review.rating >= 4) ?  'fa fa-star' :  'fa fa-star-o' ;
								const fiveSar = (review.rating >= 5) ?  'fa fa-star' :  'fa fa-star-o' ;
								return (
									<div className="recent-box text-left" key={`n${review.id}`}>
										<div className="recent">
										<h6><span><div className="rating-stars">
											<ul id="">
												<li className="star rate-this" title="1 star" data-value="1" style={{margin: 0}}>
												<i className={oneStar}></i>
												</li>
												<li className="star rate-this" title="2 stars" data-value="2" style={{margin: 0}}>
												<i className={twoStar}></i>
												</li>
												<li className="star rate-this" title="3 stars" data-value="3" style={{margin: 0}}>
												<i className={threeStar}></i>
												</li>
												<li className="star rate-this " title="4 stars" data-value="4" style={{margin: 0}}>
												<i className={fourStar}></i>
												</li>
												<li className="star rate-this " title="5 stars" data-value="5" style={{margin: 0}}>
												<i className={fiveSar}></i>
												</li>
											</ul> 
										</div></span> <span>|</span> {review.name} <span>|</span> 
											<Link to={`/colleges/${review.url}`}>{review.college}</Link> <span>|</span> <span>{review.date}</span></h6>
										<p>{review.review}</p>
										</div>
									</div>
								);
                    		})}
							</div>
						 </aside>
						 <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							<h3 className="widget-title" style={{backgroundColor: '#26272a'}}>Apply now</h3>
							<div className="widget-content p-10 side-form">
								<form onSubmit={this.handleSubmit} className="course-form clearfix">
									<div className="row">
										<div className="col-xs-12">
											<div className="text-block">
												<input type="text" name="name" placeholder="Name *" required/> </div>
										</div>
										<div className="col-xs-12">
											<div className="text-block">
												<input type="email" name="email" placeholder="Email *" required/> </div>
										</div>
										<div className="col-xs-12">
											<div className="text-block">
												<input type="text" value={mobile} onChange={e => this.updateMobile(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} className="EnquiryMobile" name="phone" placeholder="Phone *" required/> </div>
										</div>
										<div className="col-xs-12">
											<div className="text-block">
												<input type="text" name="course" placeholder="Course *" required/>
												<input type="hidden" name="source" value="2"/> </div>
										</div>
										<div className="col-xs-12 pad-5">
											<div className="text-block">
                          						<Select options={countries} name="country"  placeholder="Select Country" required onChange={this.selectCountry} />
											</div>
										</div>
                    					<div className="col-xs-12 pad-5">
											<div className="text-block">
                          						<Select options={states} value={stateValue} name="state" className="select-state" placeholder="Select State" required onChange={this.selectRegion} />
											</div>
										</div>
										<div className="col-xs-12 text-center">
											<input type="submit" value="Submit" className="btn submit"/> </div>
									</div>
								</form>
							</div>
						 </aside>
						 
						 <aside id="acadevo_contact_widget-4" className="widget-even widget-14 widget acadevo_contact_widget">
							<a className="hover" href="tel:+918095797575"><h3 className="widget-title"><i className="fa fa-phone" aria-hidden="true"></i> Helpline: +918095797575</h3></a>
						 </aside>
					  </aside>
				   </div>
              </div>
        </div>
        );
    }
}

// export default Home;